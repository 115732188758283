@import "../../utils/mixins.scss";
.input {
  // margin-bottom: 5px;
  // width:100%;
  display: flex;
  flex-direction: column;
  &:disabled {
    cursor: no-drop;
  }
  &__title {
    display: flex;
    max-width: 100%;
    font-weight: 500;
    @include heading5;
    font-weight: 600;
  }

  &__errormsg {
    font-size: 14px;
    color: red;
    text-align: left;
    //padding: 2px;
    // padding-bottom: 10px;
    font-weight: 400;
  }
  .MuiOutlinedInput-input {
    padding: 16px 14px !important;
  }
  &__icon {
    border: 1px solid #ccc;
    padding: 15px;
    border-right: none;
  }

  &__box {
    border: 1px solid #ccc;
    width: 100%;
    font-weight: 500;
    height: 46px;
    border-radius: 10px;
    padding-left: 5%;
    color: #7c7799;
    @include para1;
    font-weight: 400;

    &:focus {
      border-color: #1394ff;
      outline-offset: 0;
      outline: 0;
      box-shadow: none;
    }
    &::placeholder {
      opacity: 0.8;
      color: #7c7799;
    }
  }
  &__box-error {
    border: 1px solid #d9534f;
    width: 100%;
    font-weight: 500;
    height: 46px;
    border-radius: 10px;
    padding-left: 5%;
    color: #7c7799;
    @include para1;
    font-weight: 400;

    &:focus {
      border-color: #d9534f;
      outline-offset: 0;
      outline: 0;
      box-shadow: none;
    }
    &::placeholder {
      opacity: 0.8;
      color: #7c7799;
    }
  }

  &__cross {
    position: absolute;
    right: 0.75rem;
    align-self: center;
    z-index: 1;
  }

  &__box-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 8px;
  }
}
input:disabled {
  cursor: no-drop;
}
.input .MuiInputLabel-outlined {
  transform: translate(14px, 17px) scale(1);
}

@media only screen and (max-width: 375px) {
  .input {
    margin-bottom: 5px;
    //  width: 240px;
    width: 100%;

    display: flex;
    flex-direction: column;
    &:disabled {
      cursor: no-drop;
    }
    &__title {
      display: flex;
      max-width: 100%;
      font-weight: 500;
      @include heading5;
      font-weight: 600;
    }

    &__errormsg {
      font-size: 12px;
      color: red;
      text-align: left;
      //padding: 2px;
      // padding-bottom: 10px;
      font-weight: 400;
    }

    &__icon {
      border: 1px solid #ccc;
      padding: 15px;
      border-right: none;
    }

    &__box {
      border: 1px solid #ccc;
      width: 100%;
      font-weight: 500;
      height: 30px;
      border-radius: 10px;
      padding-left: 5%;
      color: #7c7799;
      @include para1;
      font-weight: 400;

      &:focus {
        border-color: #1394ff;
        outline-offset: 0;
        outline: 0;
        box-shadow: none;
      }
      &::placeholder {
        opacity: 0.8;
        color: #7c7799;
      }
    }
    &__box-error {
      border: 1px solid #d9534f;
      width: 100%;
      font-weight: 500;
      height: 46px;
      border-radius: 10px;
      padding-left: 5%;
      color: #7c7799;
      @include para1;
      font-weight: 400;

      &:focus {
        border-color: #d9534f;
        outline-offset: 0;
        outline: 0;
        box-shadow: none;
      }
      &::placeholder {
        opacity: 0.8;
        color: #7c7799;
      }
    }

    &__cross {
      position: absolute;
      right: 0.75rem;
      align-self: center;
      z-index: 1;
    }

    &__box-container {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 10px;
    }
  }
  input:disabled {
    cursor: no-drop;
  }
}
