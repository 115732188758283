:root {
  --primary-theme: #007ebf;
  --sidebar-theme: #000000;
  --active-sidebar-border-image: linear-gradient(to bottom, #41ad49, #007ebf) 1
    100%;
  --active-sidebar-background-image: linear-gradient(
    to left,
    rgba(65, 173, 73, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  --primary-background: #f4f7fc;
  --secondary-theme: #1e52b9;
  --ds_side_panel_color: #007ebf;
  --ds_side_tab_active_color: white;
  --ds_panel_heading_color: white;
  --ds_header_color: white;
  --ds_graph_fill_color: white;
  --ds_graph_label_color: white;
  --rating_color: black;
  --white-color: white;
  --black-color: #000;
  --page-bg-color: #ffffff;
  --border-color: #dcdcdc;
  --input-text-color: rgba(0, 0, 0, 0.87);
}
:export {
  primarytheme: --primary-theme;
}
