@import "../../utils/mixins.scss";

.large-logo {
  width: 200px;
  padding: 10px;
}
.sidebar {
  // margin: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.sidebar span {
  font-family: "Avenir" !important;
  font-size: 1.125rem !important;
  font-weight: 600;
}

.sidebar ::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.burger__menu_container {
  @media screen and (max-width: 821px) {
    position: fixed;
    left: 250px;
    top: 0%;
    // border: 2px solid red;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1272;
    transition: all 0.2s ease-in-out;
  }
}
.burger__menu {
  display: flex;
  justify-content: center;
}

.mobileView_drawer {
  @media screen and (max-width: 821px) {
    display: none;
  }
}
// .header_container{
//   @media screen and (max-width: 702) {
//     display: none;
//   }
// }
.sideDrawer__un__container {
  @media screen and (min-width: 821px) {
    width: 190px;
  }
}
