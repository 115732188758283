@import "../../utils/mixins.scss";

@import "../../utils/mixins.scss";

.Signup {
  height: 100vh;
  min-width: 100.5%;
  // width: 100%;
  overflow: hidden;

  &__trial-heading-text {
    font-size: 36px !important;
    margin-right: 10px !important;
    margin-bottom: 50px !important;
    @media only screen and (max-width: 821px) {
      margin-bottom: 0px !important;
      font-size: 30px !important;
    }
  }
  &__Side-Image {
    // background-image: url(https://network-on.s3.ap-south-1.amazonaws.com/template/54994799281636541287522-onboardimage.png);
    // height: 100vh!important;
    // background-size: contain;
    // background-size: 100% auto;

    // box-shadow: 0 60px rgba(0, 0.5) !important;

    background-position-x: center !important;
    // background-repeat: no-repeat;
  }
  .logo-image-signup {
    text-align: center;
    padding-top: 50px;
    // margin-bottom: 15px;
  }
  &__login {
    @include heading5;
    font-weight: 400;
    text-align: right;
    cursor: pointer;
    color: var(--primary-theme);
    // margin-top:20px
  }

  &__body {
    // margin-top: 60px;
    flex: 1;
    background-color: var(--page-bg-color) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // overflow: auto;
    height: calc(100vh - 170px);
    // width: 100%;
    align-items: center;
    // min-height: 500px;
    @media only screen and (max-width: 821px) {
      justify-content: flex-start;
    }
    &__page-title {
      @include heading2;
    }
  }
  &__fp-title {
    @include heading1;
    color: #261d56;
    font-weight: 500;
    justify-content: center;
    display: flex;
  }
  &__card {
    background-color: #fff;
    width: 433px;
    display: flex;
    flex-direction: column;
    padding: 2em;
    border-radius: 25px;
    box-shadow: 0 17px 24px 0 rgba(0, 126, 191, 0.26);
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 726px) {
    &__card {
      width: 95%;
      padding: 20px;
      box-shadow: 1px 1px 9px 1px #fff;
    }
    &__body {
      padding: 0;
      height: 100%;
    }
  }
}
.Signup__btn {
  min-width: 111px !important;
  color: #fff;
  border: none;
  margin-top: 20px !important;

  transition: 0.3s;
  &:hover:enabled {
    color: white;
  }
}
